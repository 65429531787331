.login {
    width: 50%;
    margin: 0 auto;
}

.login-img img {
    width: 25%;
    display: block;
    margin: 100px auto 50px;
}

.login input {
    width: 100%;
    background-color: #f2f2f2;
    border: none;
    padding: 20px;
    margin: 10px 0;
    outline: none;
    font-size: 1.25em;
    border-radius: 10px;
}

.login button {
    width: 100%;
    background-color: #67b44b;
    color: #fff;
    padding: 20px;
    outline: none;
    font-size: 1.25em;
    border-radius: 10px;
    margin: 10px 0;
    border: none;
    cursor: pointer;
}

@media only screen and (max-width: 1170px) {
    .login {
        width: 75%;
    }
}

@media only screen and (max-width: 1000px) {

}

@media only screen and (max-width: 700px) {
    .login input {
        font-size: .75em;
    }
}
