.location-buttons {
    display: grid;
    grid-template-columns: 50% 50%;
}

.location h1 {
    padding: 50px 25px;
    font-weight: 100;
    font-size: 48px;
}

.location-buttons-button {
    background-color: #67b44b;
    padding: 50px 25px;
    margin: 2% 5%;
    text-align: center;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
}

@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 1000px) {

}

@media only screen and (max-width: 450px) {
    .location-buttons {
        grid-template-columns: 100%;
    }

    .location-buttons-button {
        padding: 15px;
    }
    .location h1 {
        padding: 25px;
    }
}
