.admin-company-item {
    display: flex;
    justify-content: space-between;
}

.admin-company-item-company{
    width: 50%;
    border: 1px solid #dadada;
    margin: 10px 0;
    padding: 15px 10px;
    border-radius: 10px;
}

.admin-company-item-vat-vat  {
    border: 1px solid #dadada;
    border-radius: 10px;
    padding: 15px 10px;
    width: 90%;
}

.admin-company-item-vat svg {
    margin: 19px 0 0 0;
    cursor: pointer;
}

.admin-company-item-vat {
    width: 50%;
    margin: 10px 0;
    display: flex;
}

.admin-company-item span {
    font-weight: bolder;
}

.admin-company-item div:first-child {
    margin-right: 10px;
}
.admin-company-item div:last-child {
    margin-left: 10px;
}

.admin-company-delete {
    text-align: center;
}

.admin-company-delete h2 {
    padding: 10px 0 20px;
}

.admin-company-delete-company {
    font-weight: bolder;
}

.admin-company-delete-buttons button {
    padding: 20px 75px;
    border-radius: 10px;
    color: #fff;
    border: none;
    background-color: #b44b4b;
    margin: 25px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
}

.admin-company-delete-buttons button:last-child {
    background-color: #67b44b;
}

.admin-company-delete-location {
    font-weight: bolder;
    margin-top: 10px;
}

@media only screen and (max-width: 1170px) {
    
}

@media only screen and (max-width: 900px) {
    .admin-company-delete-buttons {
        margin-top: 25px;
    }

    .admin-company-delete-buttons button {
        width: 100%;
        margin: 10px 0;
    }

}

@media only screen and (max-width: 500px) {
    .admin-company-item {
        display: block;
        margin-bottom: 40px;
    }
    .admin-company-item-company {
        width: 100%;
    }
    .admin-company-item-street {
        width: 100%;
    }
    .admin-company-item div:last-child {
        margin-left: 0;
    }
    .admin-company-item-vat {
        width: 100%;
    }
}