.registration-header {
    display: flex;
    justify-content: space-between;
}

.registration-header h1 {
    padding: 25px;
    font-weight: 100;
    font-size: 48px;
}


.registration-options {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
    padding-left: 25px;
    padding-top: 25px;
}

.registration-options div {
    cursor: pointer;
}

.registration-options img {
    height: 20px;
}

.registration-excel {
    color: #fff;
    background-color: #000;
    padding: 10px;
    padding-top: 14px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    height: 50px;
}


.registration-options-dropdown {
    border: #dadada 1px solid;
    padding-top: 13px;
    padding-left: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.registration-options-dropdown svg {
    margin: 3px 15px 0 0;
}

.registration-cards {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-column-gap: 5%;
    grid-row-gap: 1%;
    padding-left: 25px;
    padding-top: 25px;
}

.download-icon {
    margin: 3px 5px 0 0;
}

.registration-dropdown-items {
    position: absolute;
    top: 49px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #dadada;
    width: 90%;
    padding: 5%;
    list-style-type: none;
    z-index: 100000000;
}

.registration-dropdown-items li {
    padding: 2% 5%;
}

.registration-dropdown-items li:hover {
    background-color: #67b44b;
}

.registration-options-dropdown input {
    border: none;
    font-size: 1em;
    width: 95%;
    outline: none;
    margin-top: 5px;
    margin-left: 5px;
    cursor: pointer;
}

@media only screen and (max-width: 1170px) {
    .registration {
        padding: 10px;
    }

    .registration-options {
        padding-left: 0;
    }

    .registration-cards {
        padding-left: 0;
    }
}

@media only screen and (max-width: 800px) {
    .registration-options {
        grid-template-columns: 100%;
    }
    .registration-cards {
        grid-template-columns: 49% 49%;
        grid-gap: 15px;
    }
}

@media only screen and (max-width: 550px) {
    .registration-cards {
        grid-template-columns: 100%;
    }
}
