.toolbar {
    width: 250px;
    background-color: #000;
    height: 100vh;
}

.toolbar-header a {
    text-decoration: none;
    color: #fff;
}

.toolbar-header h2{
    color: #fff;
    padding-top: 25px;
    padding-left: 15px;
    font-size: 21px;
}

.toolbar-header h3 {
    color: #fff;
    padding-left: 15px;
    font-weight: 100;
    font-size: 17px;

}

.toolbar-header button {
    display: block;
    margin: 20px auto;
    padding: 15px 15px;
    font-size: 15px;
    border: none;
    background-color: #67b44b;
    color: #fff;
    border-radius: 15px;
    outline: none;
    cursor: pointer;
}

.toolbar hr {
    margin: 0 15px;
}

.toolbar ul{
    list-style-type: none;
    margin: 20px 0;
    margin-bottom: 400px;
}

.toolbar ul li {
    padding-left: 15px;
    padding-top: 10px;
    cursor: pointer;
    text-decoration: none;
}

.toolbar ul li a {
    color: #fff;
}

.toolbar-img {
    display: block;
    margin: 0 auto;
    padding: 0 20px;
    margin-top: 50px;
}

.toolbar-img img{
    height: auto;
    width: 100%;
}

.toolbar-header-link {
    color: #fff;
    text-decoration: none;
}

.toolbar-header-link-active {
    font-weight: bold;
}

.icon {
    margin-right: 10px;
}

.toolbar-header-menu {
    display: none;
}

@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 1000px) {
    .toolbar {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .toolbar-header-ul {
        display: none;
        flex-direction: column;
    }
    .toolbar ul li {
        width: 100%;
        display: block;
        text-align: center;
        font-size: 2em;
    }
    
    .toolbar-img img {
        display: none;
    }
    
    .toolbar-header-menu {
        display: block;
    }
    .toolbar-header-menu img {
        float: right;
        cursor: pointer;
        padding: 25px;

    }
    .toolbar-header {
        display: none;
        flex-direction: column;
        justify-content: space-around;
    }

    .toolbar-header button {
        width: 75%;
    }

    .toolbar h2, .toolbar h3 {
        text-align: center;
        color: #fff;
        padding: 5px;
    }

    .toolbar ul {
        margin-bottom: 75px;
    }

    .responsive-toolbar-header button {
        display: block;
        margin: 20px auto;
        padding: 15px 15px;
        width: 75%;
        font-size: 15px;
        border: none;
        background-color: #67b44b;
        color: #fff;
        border-radius: 15px;
        outline: none;
        cursor: pointer;
    }
    .toolbar-img {
        display: none;
    }
    a {
        text-decoration: none;
    }
}

@media only screen and (max-width: 700px) {

}

@media only screen and (max-width: 400px) {
    .responsive-menu {
        font-size: .7em;
    }
}
