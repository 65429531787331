.edit-password-modal-edit-h2 {
    text-align: center;
}

.edit-password-modal-edit-info {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-gap: 2%;
}

.edit-password-modal-edit-info input {
    padding: 15px;
    border: 1px solid #dadada;
    border-radius: 10px;
    outline: none;
}

.edit-password-modal-edit-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

.edit-password-modal-edit-buttons button {
    padding: 20px 75px;
    border-radius: 10px;
    color: #fff;
    border: none;
    background-color: #b44b4b;
    margin-bottom: 15px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
    width: 48%;
}

.edit-password-modal-edit-buttons button:last-child {
    background-color: #67b44b;
}