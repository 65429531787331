.admin-create {
    padding-left: 25px;
    padding-top: 50px;
}

.admin-create div:first-child {
    display: flex;
    justify-content: space-between;
}

.admin-create div:first-child input {
    padding: 15px;
    border: 1px solid #dadada;
    border-radius: 10px;
    outline: none;
}

.admin-create div:first-child input:first-child {
    width: 50%;
    margin-right: 10px;
}

.admin-create div:first-child input:last-child {
    width: 50%;
    margin-left: 10px;
}

.admin-create-info {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
    margin: 50px 0;
}

.admin-create input {
    border-radius: 10px;
    padding: 15px;
    border: 1px solid #dadada;
    outline: none;
}

.admin-create button {
    width: 100%;
    padding: 15px;
    background-color: #67b44b;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 10px;
    outline: none;
}

.admin-create-dropdown span {
    font-weight: bolder;
}

.admin-create-dropdown svg {
    margin-top: 4px;
}

.registration-options-dropdown {
    cursor: pointer;
}

@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 900px) {
    .admin-create div:first-child input:first-child {
        width: 100%;
        margin-right: 0;
    }
    .admin-create div:first-child input:last-child {
        margin-top: 25px;
        width: 100%;
        margin-left: 0;
    }
    .admin-create {
        padding: 25px 10px 10px 10px;
    }

    .admin-create div:first-child {
        display: block;
    }

    .registration-options-dropdown {
        padding: 13px;
    }

    .admin-create-info div:first-child {
        display: flex;
    }
    .admin-create-info {
        display: block;
        margin-top: 15px;
    }
    .admin-create-info input {
        width: 100%;
        margin-top: 15px;
    }
}

@media only screen and (max-width: 500px) {

}