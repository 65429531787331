.admin h1 {
    padding: 50px 25px;
    font-weight: 100;
    font-size: 48px;
}

.admin-edit-button {
    width: 97%;
    margin-left: 25px;
    padding: 15px;
    background-color: #67b44b;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
}

@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 900px) {
    .admin-edit-button {
        padding: 10px;
        margin-left: 10px;
    }
}

@media only screen and (max-width: 500px) {

}