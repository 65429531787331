.wrapper {
    display: flex;
    width: 1170px;
    margin: 0 auto;
}

.wrapper main {
    width: 100%;
}

.wrapper-user-info {
    padding: 25px;
    display: flex;
}

.wrapper-user-info span {
    font-weight: bold;
}

.wrapper-user-info-img {
    width: 20px;
    margin-top: 1px;
    margin-left: 5px;
}

.wrapper-user-info svg {
    margin-left: 5px;
    cursor: pointer;
    color: #000;
}

.wrapper-user-info-dropdown {
    position: fixed;
    margin-top: 25px;
    border: 1px solid #dadada;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    width: 200px;
}

.wrapper-user-info-dropdown ul {
    list-style-type: none;
}

.wrapper-user-info-dropdown p {
    font-weight: bolder;
    padding: 10px 0;
    color: #000;
    cursor: pointer;
}

.wrapper-user-info-dropdown a {
    text-decoration: none;
}

@media only screen and (max-width: 1170px) {
    .wrapper {
        width: 100%;
    }
}

@media only screen and (max-width: 1000px) {
    .wrapper {
        flex-direction: column;
    }
}

@media only screen and (max-width: 700px) {

}
