.admin-location h1 {
    padding: 50px 25px;
    font-size: 48px;
    font-weight: 100;
}

.admin-locations-button {
    margin-top: 35px;
    width: 97%;
    padding: 15px;
    background-color: #67b44b;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
    margin-left: 25px;
}


@media only screen and (max-width: 1170px) {
    
    .admin-locations-button {
        margin-left: 0px;
        margin: 10px;
    }
}

@media only screen and (max-width: 950px) {

}

@media only screen and (max-width: 500px) {

}