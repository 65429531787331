.company h1 {
    padding: 50px 25px;
    font-weight: 100;
    font-size: 48px;
}

.company-button {
    margin-top: 25px;
    margin-left: 25px;
    width: 97%;
    padding: 15px;
    background-color: #67b44b;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
}

@media only screen and (max-width: 1170px) {
    .company-button {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 950px) {

}

@media only screen and (max-width: 500px) {

}