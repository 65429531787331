.registration-card-item {
    background-color: #dadada;
    border-radius: 10px;
    transition: all 1s ease-out;
}

.registration-card-item-head {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    padding-left: 20px;
}

.registration-card-item-head p {
    font-weight: bolder;
    font-size: 16px;
    padding-top: 7px;
}

.registration-card-item-head img {
    height: 20px;
    cursor: pointer;
    margin-top: 8px;
}

.registration-card-item-info {
    background-color: #fff;
    margin: 10px;
    border-radius: 10px;
    padding: 10px;
    display: grid;
    grid-template-columns: 50% 50%;
}

.registration-card-item-info div {
    margin: 15px 0;
}

.registration-card-item-info-head {
    font-weight: bolder;
}

.registration-card-item-head-company {
    text-transform: uppercase; 
}

.registration-card-item-head svg {
    margin: 10px 5px 0 0;
    cursor: pointer;
}

.registration-delete {
    text-align: center;
}

.registration-delete h2 {
    padding: 10px 0 20px;
}

.registration-delete span {
    font-weight: bolder;
}

.registration-delete-buttons button {
    padding: 20px 75px;
    border-radius: 10px;
    color: #fff;
    border: none;
    background-color: #b44b4b;
    margin: 25px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
}

.registration-delete-buttons button:last-child {
    background-color: #67b44b;
}


@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 900px) {
    .registration-delete-buttons {
        display: block;
        margin-top: 25px;
    }
    .registration-delete-buttons button {
        width: 100%;
        margin: 10px 0;
    }

}

@media only screen and (max-width: 700px) {

}
