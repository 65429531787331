.admin-user {
    margin: 25px 0 25px 0;
    width: 100%;
    border: 1px solid #dadada;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
}

.admin-user span {
    font-weight: bolder;
}

.admin-user-icons {
    display: flex;
}

.admin-user-icons svg { 
    display: block;
    margin: 4px 7px;
    cursor: pointer;
}

.admin-user-delete {
    text-align: center;
}

.admin-user-delete h2 {
    padding: 10px 0 20px;
}

.admin-user-delete-user {
    font-weight: bolder;
}

.admin-user-delete-buttons button {
    padding: 20px 75px;
    border-radius: 10px;
    color: #fff;
    border: none;
    background-color: #b44b4b;
    margin: 25px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
}

.admin-user-delete-buttons button:last-child {
    background-color: #67b44b;
}

.admin-user-delete-location {
    font-weight: bolder;
    margin-top: 10px;
}

@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 900px) {
    .admin-user-delete-buttons {
        margin-top: 15px;
        display: block;
    }

    .admin-user-delete-buttons button {
        width: 100%;
        margin: 10px 0;
    }

}

@media only screen and (max-width: 500px) {

}