.manual h1 {
    padding: 50px 25px;
    font-weight: 100;
    font-size: 48px;
}

.manual-options {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
    padding-left: 25px;
    padding-top: 25px;
}

.manual-options-dropdown {
    border: #dadada 1px solid;
    padding-left: 10px;
    border-radius: 10px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
}

.manual-options-dropdown p {
    padding-top: 13px;
    width: 150px;
}

.manual-options-dropdown:last-child p {
    width: 50px;
}

.manual-options-dropdown i {
    padding-top: 13px;
}

.manual-button {
    width: 97.5%;
    margin-left: 25px;
    padding: 15px;
    background-color: #67b44b;
    border: none;
    margin-top: 25px;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
}

.manual-options-dropdown img {
    height: 20px;
}

.manual-options-dropdown svg {
    margin-top: 3px;
}

.manual-dropdown-calendar {
    padding: 0;
}

.dropdown-calendar {
    padding: 7% 0 6.5%;
    width: 100%;
    border: none;
    font-size: 1em;
    outline: none;
}

.manual-dropdown-calendar p {
    padding-top: 12px;
    padding-left: 7px;
}


.calendar-dropdown-icon {
    padding-top: 12px;
    padding-right: 15px;
}

.manual-dropdown-calendar p {
    display: inline;
}

.manual-options-dropdown input {
    border: none;
    font-size: 1em;
    width: 95%;
    outline: none;
    margin-left: 5px;
    cursor: pointer;
}

.ant-time-picker  {
    width:100%;
    margin-top: 8px;
    margin-left: 0px;
    color: black;
}

@media only screen and (max-width: 1170px) {
    .manual {
        padding: 15px;
    }
}

@media only screen and (max-width: 950px) {
    .manual-options {
        grid-template-columns: 100%;
    }

    .react-datepicker-popper {
        position: relative;
    }
}

@media only screen and (max-width: 700px) {
    .manual-button {
        width:95%;
    }
}
