.registration-header-end-button {
    padding: 7px 0;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #b44b4b;
    color: #fff;
    font-size: 32px;
    border: none;
    border-radius: 15px;
    outline: none;
    cursor: pointer;
    width: 300px;
}

.registration-header-start-button {
    padding: 20px 0px;
    margin-top: 15px;
    background-color: #67b44b;
    color: #fff;
    font-size: 32px;
    border: none;
    border-radius: 15px;
    outline: none;
    cursor: pointer;
    width: 300px;
    margin-bottom: 15px;
}

.registration-header-time {
    text-align: center;
    font-weight: bolder;
    font-size: 32px;
}

@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 800px) {
    .registration-header {
        flex-direction: column;
    }
    .registration-header h1 {
        text-align: center;
    }
    .registration-header-start-button, .registration-header-end-button {
        width: 100%;
    }
}

@media only screen and (max-width: 700px) {

}
