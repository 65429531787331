* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}
.wrapper {
    display: flex;
    width: 1170px;
    margin: 0 auto;
}

.wrapper main {
    width: 100%;
}

.wrapper-user-info {
    padding: 25px;
    display: flex;
}

.wrapper-user-info span {
    font-weight: bold;
}

.wrapper-user-info-img {
    width: 20px;
    margin-top: 1px;
    margin-left: 5px;
}

.wrapper-user-info svg {
    margin-left: 5px;
    cursor: pointer;
    color: #000;
}

.wrapper-user-info-dropdown {
    position: fixed;
    margin-top: 25px;
    border: 1px solid #dadada;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    width: 200px;
}

.wrapper-user-info-dropdown ul {
    list-style-type: none;
}

.wrapper-user-info-dropdown p {
    font-weight: bolder;
    padding: 10px 0;
    color: #000;
    cursor: pointer;
}

.wrapper-user-info-dropdown a {
    text-decoration: none;
}

@media only screen and (max-width: 1170px) {
    .wrapper {
        width: 100%;
    }
}

@media only screen and (max-width: 1000px) {
    .wrapper {
        flex-direction: column;
    }
}

@media only screen and (max-width: 700px) {

}

.toolbar {
    width: 250px;
    background-color: #000;
    height: 100vh;
}

.toolbar-header a {
    text-decoration: none;
    color: #fff;
}

.toolbar-header h2{
    color: #fff;
    padding-top: 25px;
    padding-left: 15px;
    font-size: 21px;
}

.toolbar-header h3 {
    color: #fff;
    padding-left: 15px;
    font-weight: 100;
    font-size: 17px;

}

.toolbar-header button {
    display: block;
    margin: 20px auto;
    padding: 15px 15px;
    font-size: 15px;
    border: none;
    background-color: #67b44b;
    color: #fff;
    border-radius: 15px;
    outline: none;
    cursor: pointer;
}

.toolbar hr {
    margin: 0 15px;
}

.toolbar ul{
    list-style-type: none;
    margin: 20px 0;
    margin-bottom: 400px;
}

.toolbar ul li {
    padding-left: 15px;
    padding-top: 10px;
    cursor: pointer;
    text-decoration: none;
}

.toolbar ul li a {
    color: #fff;
}

.toolbar-img {
    display: block;
    margin: 0 auto;
    padding: 0 20px;
    margin-top: 50px;
}

.toolbar-img img{
    height: auto;
    width: 100%;
}

.toolbar-header-link {
    color: #fff;
    text-decoration: none;
}

.toolbar-header-link-active {
    font-weight: bold;
}

.icon {
    margin-right: 10px;
}

.toolbar-header-menu {
    display: none;
}

@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 1000px) {
    .toolbar {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .toolbar-header-ul {
        display: none;
        flex-direction: column;
    }
    .toolbar ul li {
        width: 100%;
        display: block;
        text-align: center;
        font-size: 2em;
    }
    
    .toolbar-img img {
        display: none;
    }
    
    .toolbar-header-menu {
        display: block;
    }
    .toolbar-header-menu img {
        float: right;
        cursor: pointer;
        padding: 25px;

    }
    .toolbar-header {
        display: none;
        flex-direction: column;
        justify-content: space-around;
    }

    .toolbar-header button {
        width: 75%;
    }

    .toolbar h2, .toolbar h3 {
        text-align: center;
        color: #fff;
        padding: 5px;
    }

    .toolbar ul {
        margin-bottom: 75px;
    }

    .responsive-toolbar-header button {
        display: block;
        margin: 20px auto;
        padding: 15px 15px;
        width: 75%;
        font-size: 15px;
        border: none;
        background-color: #67b44b;
        color: #fff;
        border-radius: 15px;
        outline: none;
        cursor: pointer;
    }
    .toolbar-img {
        display: none;
    }
    a {
        text-decoration: none;
    }
}

@media only screen and (max-width: 700px) {

}

@media only screen and (max-width: 400px) {
    .responsive-menu {
        font-size: .7em;
    }
}

.login {
    width: 50%;
    margin: 0 auto;
}

.login-img img {
    width: 25%;
    display: block;
    margin: 100px auto 50px;
}

.login input {
    width: 100%;
    background-color: #f2f2f2;
    border: none;
    padding: 20px;
    margin: 10px 0;
    outline: none;
    font-size: 1.25em;
    border-radius: 10px;
}

.login button {
    width: 100%;
    background-color: #67b44b;
    color: #fff;
    padding: 20px;
    outline: none;
    font-size: 1.25em;
    border-radius: 10px;
    margin: 10px 0;
    border: none;
    cursor: pointer;
}

@media only screen and (max-width: 1170px) {
    .login {
        width: 75%;
    }
}

@media only screen and (max-width: 1000px) {

}

@media only screen and (max-width: 700px) {
    .login input {
        font-size: .75em;
    }
}

.admin-create {
    padding-left: 25px;
    padding-top: 50px;
}

.admin-create div:first-child {
    display: flex;
    justify-content: space-between;
}

.admin-create div:first-child input {
    padding: 15px;
    border: 1px solid #dadada;
    border-radius: 10px;
    outline: none;
}

.admin-create div:first-child input:first-child {
    width: 50%;
    margin-right: 10px;
}

.admin-create div:first-child input:last-child {
    width: 50%;
    margin-left: 10px;
}

.admin-create-info {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
    margin: 50px 0;
}

.admin-create input {
    border-radius: 10px;
    padding: 15px;
    border: 1px solid #dadada;
    outline: none;
}

.admin-create button {
    width: 100%;
    padding: 15px;
    background-color: #67b44b;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 10px;
    outline: none;
}

.admin-create-dropdown span {
    font-weight: bolder;
}

.admin-create-dropdown svg {
    margin-top: 4px;
}

.registration-options-dropdown {
    cursor: pointer;
}

@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 900px) {
    .admin-create div:first-child input:first-child {
        width: 100%;
        margin-right: 0;
    }
    .admin-create div:first-child input:last-child {
        margin-top: 25px;
        width: 100%;
        margin-left: 0;
    }
    .admin-create {
        padding: 25px 10px 10px 10px;
    }

    .admin-create div:first-child {
        display: block;
    }

    .registration-options-dropdown {
        padding: 13px;
    }

    .admin-create-info div:first-child {
        display: flex;
    }
    .admin-create-info {
        display: block;
        margin-top: 15px;
    }
    .admin-create-info input {
        width: 100%;
        margin-top: 15px;
    }
}

@media only screen and (max-width: 500px) {

}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 500;
}
.modal {
    position: relative;
    margin: 0 auto;
    height: auto;
    margin-top: 15%;
    width: 900px;
    background-color: rgb(255, 255, 255);
    z-index: 10000;
    cursor: default;
    border-radius: 10px;
    padding: 25px;
    box-shadow: 4px 6px 76px -8px rgba(0,0,0,0.44);
}

/* Media Query */

@media only screen and (max-width: 930px) {
    .modal {
        width: 98%;
    }
}

.user-modal-edit {
    padding-top: 50px;
}

.user-modal-edit div:first-child {
    display: flex;
    justify-content: space-between;
}

.user-modal-edit div:first-child input {
    padding: 15px;
    border: 1px solid #dadada;
    border-radius: 10px;
    outline: none;
}

.user-modal-edit div:first-child input:first-child {
    width: 100%;

}

.user-modal-edit-info {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
    margin: 50px 0;
}

.user-modal-edit input {
    border-radius: 10px;
    padding: 15px;
    border: 1px solid #dadada;
    outline: none;
}

.user-modal-edit button {
    width: 100%;
    padding: 15px;
    background-color: #67b44b;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
}

.user-modal-edit-dropdown {
    border-radius: 10px;
    padding: 15px;
    border: 1px solid #dadada;
    display: flex;
    justify-content: space-between;
}

.user-modal-edit-dropdown span {
    font-weight: bolder;
}

.user-modal-edit-dropdown svg {
    margin-top: 4px;
}

.user-modal-edit-h2 {
    text-align: center;
}

.user-modal-edit-buttons {
    display: flex;
    justify-content: space-between;
}

.user-modal-edit-buttons button {
    padding: 20px 75px;
    border-radius: 10px;
    color: #fff;
    border: none;
    background-color: #b44b4b;
    margin-bottom: 15px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
    width: 48%;
}

.user-modal-edit-buttons button:last-child {
    background-color: #67b44b;
}

@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 900px) {
    .user-modal-edit-info {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 5%;
        margin: 50px 0;
    }

    .user-modal-edit div:first-child {
        display: block;
        justify-content: space-between;
    }

    .user-modal-edit-info div:first-child {
        display: flex;
        justify-content: space-between;
        margin: 0;
    }

    .user-modal-edit div:first-child input:first-child {
        width: 100%;
        margin-right: 0;
    }
    
    .user-modal-edit div:first-child input:last-child {
        width: 100%;
        margin-left: 0;
    }

    .user-modal-edit-info {
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .user-modal-edit div:first-child input {
        margin: 10px 0;
    }
    .user-modal-edit-buttons {
        display: block;
        justify-content: space-between;
    }
    .user-modal-edit-buttons button {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {

}
.edit-password-modal-edit-h2 {
    text-align: center;
}

.edit-password-modal-edit-info {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-gap: 2%;
}

.edit-password-modal-edit-info input {
    padding: 15px;
    border: 1px solid #dadada;
    border-radius: 10px;
    outline: none;
}

.edit-password-modal-edit-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

.edit-password-modal-edit-buttons button {
    padding: 20px 75px;
    border-radius: 10px;
    color: #fff;
    border: none;
    background-color: #b44b4b;
    margin-bottom: 15px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
    width: 48%;
}

.edit-password-modal-edit-buttons button:last-child {
    background-color: #67b44b;
}
.admin-user {
    margin: 25px 0 25px 0;
    width: 100%;
    border: 1px solid #dadada;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
}

.admin-user span {
    font-weight: bolder;
}

.admin-user-icons {
    display: flex;
}

.admin-user-icons svg { 
    display: block;
    margin: 4px 7px;
    cursor: pointer;
}

.admin-user-delete {
    text-align: center;
}

.admin-user-delete h2 {
    padding: 10px 0 20px;
}

.admin-user-delete-user {
    font-weight: bolder;
}

.admin-user-delete-buttons button {
    padding: 20px 75px;
    border-radius: 10px;
    color: #fff;
    border: none;
    background-color: #b44b4b;
    margin: 25px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
}

.admin-user-delete-buttons button:last-child {
    background-color: #67b44b;
}

.admin-user-delete-location {
    font-weight: bolder;
    margin-top: 10px;
}

@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 900px) {
    .admin-user-delete-buttons {
        margin-top: 15px;
        display: block;
    }

    .admin-user-delete-buttons button {
        width: 100%;
        margin: 10px 0;
    }

}

@media only screen and (max-width: 500px) {

}
.admin-edit {
    padding-left: 25px;
    padding-top: 50px;
}

.admin-edit h2 {
    padding-top: 50px;
    padding-bottom: 25px;
}

@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 900px) {
    .admin-edit {
        padding: 0;
        padding: 10px;
    }
}

@media only screen and (max-width: 500px) {

}
.admin h1 {
    padding: 50px 25px;
    font-weight: 100;
    font-size: 48px;
}

.admin-edit-button {
    width: 97%;
    margin-left: 25px;
    padding: 15px;
    background-color: #67b44b;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
}

@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 900px) {
    .admin-edit-button {
        padding: 10px;
        margin-left: 10px;
    }
}

@media only screen and (max-width: 500px) {

}
.registration-header-end-button {
    padding: 7px 0;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #b44b4b;
    color: #fff;
    font-size: 32px;
    border: none;
    border-radius: 15px;
    outline: none;
    cursor: pointer;
    width: 300px;
}

.registration-header-start-button {
    padding: 20px 0px;
    margin-top: 15px;
    background-color: #67b44b;
    color: #fff;
    font-size: 32px;
    border: none;
    border-radius: 15px;
    outline: none;
    cursor: pointer;
    width: 300px;
    margin-bottom: 15px;
}

.registration-header-time {
    text-align: center;
    font-weight: bolder;
    font-size: 32px;
}

@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 800px) {
    .registration-header {
        flex-direction: column;
    }
    .registration-header h1 {
        text-align: center;
    }
    .registration-header-start-button, .registration-header-end-button {
        width: 100%;
    }
}

@media only screen and (max-width: 700px) {

}

.registration-card-item {
    background-color: #dadada;
    border-radius: 10px;
    transition: all 1s ease-out;
}

.registration-card-item-head {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    padding-left: 20px;
}

.registration-card-item-head p {
    font-weight: bolder;
    font-size: 16px;
    padding-top: 7px;
}

.registration-card-item-head img {
    height: 20px;
    cursor: pointer;
    margin-top: 8px;
}

.registration-card-item-info {
    background-color: #fff;
    margin: 10px;
    border-radius: 10px;
    padding: 10px;
    display: grid;
    grid-template-columns: 50% 50%;
}

.registration-card-item-info div {
    margin: 15px 0;
}

.registration-card-item-info-head {
    font-weight: bolder;
}

.registration-card-item-head-company {
    text-transform: uppercase; 
}

.registration-card-item-head svg {
    margin: 10px 5px 0 0;
    cursor: pointer;
}

.registration-delete {
    text-align: center;
}

.registration-delete h2 {
    padding: 10px 0 20px;
}

.registration-delete span {
    font-weight: bolder;
}

.registration-delete-buttons button {
    padding: 20px 75px;
    border-radius: 10px;
    color: #fff;
    border: none;
    background-color: #b44b4b;
    margin: 25px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
}

.registration-delete-buttons button:last-child {
    background-color: #67b44b;
}


@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 900px) {
    .registration-delete-buttons {
        display: block;
        margin-top: 25px;
    }
    .registration-delete-buttons button {
        width: 100%;
        margin: 10px 0;
    }

}

@media only screen and (max-width: 700px) {

}

.registration-header {
    display: flex;
    justify-content: space-between;
}

.registration-header h1 {
    padding: 25px;
    font-weight: 100;
    font-size: 48px;
}


.registration-options {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
    padding-left: 25px;
    padding-top: 25px;
}

.registration-options div {
    cursor: pointer;
}

.registration-options img {
    height: 20px;
}

.registration-excel {
    color: #fff;
    background-color: #000;
    padding: 10px;
    padding-top: 14px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    height: 50px;
}


.registration-options-dropdown {
    border: #dadada 1px solid;
    padding-top: 13px;
    padding-left: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.registration-options-dropdown svg {
    margin: 3px 15px 0 0;
}

.registration-cards {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-column-gap: 5%;
    grid-row-gap: 1%;
    padding-left: 25px;
    padding-top: 25px;
}

.download-icon {
    margin: 3px 5px 0 0;
}

.registration-dropdown-items {
    position: absolute;
    top: 49px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #dadada;
    width: 90%;
    padding: 5%;
    list-style-type: none;
    z-index: 100000000;
}

.registration-dropdown-items li {
    padding: 2% 5%;
}

.registration-dropdown-items li:hover {
    background-color: #67b44b;
}

.registration-options-dropdown input {
    border: none;
    font-size: 1em;
    width: 95%;
    outline: none;
    margin-top: 5px;
    margin-left: 5px;
    cursor: pointer;
}

@media only screen and (max-width: 1170px) {
    .registration {
        padding: 10px;
    }

    .registration-options {
        padding-left: 0;
    }

    .registration-cards {
        padding-left: 0;
    }
}

@media only screen and (max-width: 800px) {
    .registration-options {
        grid-template-columns: 100%;
    }
    .registration-cards {
        grid-template-columns: 49% 49%;
        grid-gap: 15px;
    }
}

@media only screen and (max-width: 550px) {
    .registration-cards {
        grid-template-columns: 100%;
    }
}

.location-buttons {
    display: grid;
    grid-template-columns: 50% 50%;
}

.location h1 {
    padding: 50px 25px;
    font-weight: 100;
    font-size: 48px;
}

.location-buttons-button {
    background-color: #67b44b;
    padding: 50px 25px;
    margin: 2% 5%;
    text-align: center;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
}

@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 1000px) {

}

@media only screen and (max-width: 450px) {
    .location-buttons {
        grid-template-columns: 100%;
    }

    .location-buttons-button {
        padding: 15px;
    }
    .location h1 {
        padding: 25px;
    }
}

.manual h1 {
    padding: 50px 25px;
    font-weight: 100;
    font-size: 48px;
}

.manual-options {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
    padding-left: 25px;
    padding-top: 25px;
}

.manual-options-dropdown {
    border: #dadada 1px solid;
    padding-left: 10px;
    border-radius: 10px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
}

.manual-options-dropdown p {
    padding-top: 13px;
    width: 150px;
}

.manual-options-dropdown:last-child p {
    width: 50px;
}

.manual-options-dropdown i {
    padding-top: 13px;
}

.manual-button {
    width: 97.5%;
    margin-left: 25px;
    padding: 15px;
    background-color: #67b44b;
    border: none;
    margin-top: 25px;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
}

.manual-options-dropdown img {
    height: 20px;
}

.manual-options-dropdown svg {
    margin-top: 3px;
}

.manual-dropdown-calendar {
    padding: 0;
}

.dropdown-calendar {
    padding: 7% 0 6.5%;
    width: 100%;
    border: none;
    font-size: 1em;
    outline: none;
}

.manual-dropdown-calendar p {
    padding-top: 12px;
    padding-left: 7px;
}


.calendar-dropdown-icon {
    padding-top: 12px;
    padding-right: 15px;
}

.manual-dropdown-calendar p {
    display: inline;
}

.manual-options-dropdown input {
    border: none;
    font-size: 1em;
    width: 95%;
    outline: none;
    margin-left: 5px;
    cursor: pointer;
}

.ant-time-picker  {
    width:100%;
    margin-top: 8px;
    margin-left: 0px;
    color: black;
}

@media only screen and (max-width: 1170px) {
    .manual {
        padding: 15px;
    }
}

@media only screen and (max-width: 950px) {
    .manual-options {
        grid-template-columns: 100%;
    }

    .react-datepicker-popper {
        position: relative;
    }
}

@media only screen and (max-width: 700px) {
    .manual-button {
        width:95%;
    }
}

.edit h1 {
    padding: 50px 25px;
    font-weight: 100;
    font-size: 48px;
}

.edit-inputs {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
    padding-left: 25px;
}

.edit-input-div label, .edit-input-div input {
    display: inline-block;
}
.edit-input-div input {
    border: none;
    outline: none;
    padding-left: 1px;
    font-size: 15px;
    font-weight: bolder;
}

.edit-input-div {
    border: #dadada 1px solid;
    padding: 10px;
    margin: 25px 0;
    border-radius: 10px;
}

.edit-input {
    border-radius: 10px;
    border: #dadada 1px solid;
    padding: 10px;
    font-size: 15px;
    outline: none;
}

.edit-password {
    text-align: center;
    padding: 30px;
}

.edit-input-buttons button {
    margin-left: 25px;
    width: 97.5%;
    padding: 15px 20px;
    background-color: #67b44b;
    border: none;
    color: #fff;
    font-size: 15px;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
}

.edit-input-buttons button:last-child {
    margin-top: 25px;
}

.edit-password-inputs {
    display: flex;
    justify-content: space-between;
}

.edit-password input {
    width: 47%;
}

@media only screen and (max-width: 1170px) {
    .edit {
        padding: 15px;
    }
}

@media only screen and (max-width: 800px) {
    .edit-inputs {
        grid-template-columns: 100%;
        grid-gap: 0%;
        padding-left: 25px;
    }

    .edit-input-buttons button {
        width: 93%;
    }

    .edit-password input {
        width: 100%;
        margin: 5px 0;
    }

    .edit-password-inputs {
        display: block;
    }

    .edit-input-div {
        margin: 10px 0;
    }
}

@media only screen and (max-width: 700px) {
    .manual-button {
        width:95%;
    }
}

.admin-company-create {
    padding-left: 25px;
    padding-top: 25px;
}

.admin-company-create-inputs {
    display: flex;
    justify-content: space-between;
}

.admin-company-create-inputs input:first-child {
    width: 50%;
    margin-right: 10px;
}

.admin-company-create-inputs input:last-child {
    width: 50%;
    margin-left: 10px;
}

.admin-company-create-inputs input {
    padding: 15px;
    border: 1px solid #dadada;
    border-radius: 10px;
    outline: none;
}

.admin-company-create button {
    margin-top: 35px;
    width: 100%;
    padding: 15px;
    background-color: #67b44b;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
}


@media only screen and (max-width: 1170px) {
    .admin-company-create {
        padding-left: 0;
        padding: 10px;
    }
}

@media only screen and (max-width: 950px) {

}

@media only screen and (max-width: 500px) {

    .admin-company-create-inputs {
        display: block;
    }
    .admin-company-create-inputs input:first-child {
        width: 100%;
        margin: 0;
        margin-top: 10px;
    }
    
    .admin-company-create-inputs input:last-child {
        width: 100%;
        margin: 0;
        margin-top: 10px;
    }


}
.admin-company-item {
    display: flex;
    justify-content: space-between;
}

.admin-company-item-company{
    width: 50%;
    border: 1px solid #dadada;
    margin: 10px 0;
    padding: 15px 10px;
    border-radius: 10px;
}

.admin-company-item-vat-vat  {
    border: 1px solid #dadada;
    border-radius: 10px;
    padding: 15px 10px;
    width: 90%;
}

.admin-company-item-vat svg {
    margin: 19px 0 0 0;
    cursor: pointer;
}

.admin-company-item-vat {
    width: 50%;
    margin: 10px 0;
    display: flex;
}

.admin-company-item span {
    font-weight: bolder;
}

.admin-company-item div:first-child {
    margin-right: 10px;
}
.admin-company-item div:last-child {
    margin-left: 10px;
}

.admin-company-delete {
    text-align: center;
}

.admin-company-delete h2 {
    padding: 10px 0 20px;
}

.admin-company-delete-company {
    font-weight: bolder;
}

.admin-company-delete-buttons button {
    padding: 20px 75px;
    border-radius: 10px;
    color: #fff;
    border: none;
    background-color: #b44b4b;
    margin: 25px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
}

.admin-company-delete-buttons button:last-child {
    background-color: #67b44b;
}

.admin-company-delete-location {
    font-weight: bolder;
    margin-top: 10px;
}

@media only screen and (max-width: 1170px) {
    
}

@media only screen and (max-width: 900px) {
    .admin-company-delete-buttons {
        margin-top: 25px;
    }

    .admin-company-delete-buttons button {
        width: 100%;
        margin: 10px 0;
    }

}

@media only screen and (max-width: 500px) {
    .admin-company-item {
        display: block;
        margin-bottom: 40px;
    }
    .admin-company-item-company {
        width: 100%;
    }
    .admin-company-item-street {
        width: 100%;
    }
    .admin-company-item div:last-child {
        margin-left: 0;
    }
    .admin-company-item-vat {
        width: 100%;
    }
}
.company-edit {
    margin-left: 25px;
    margin-top: 50px;
}

.company-edit h2 {
    margin-bottom: 30px;
}

.company-edit-button {
    margin-top: 25px;
    width: 100%;
    padding: 15px;
    background-color: #67b44b;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
}


@media only screen and (max-width: 1170px) {
    .company-edit {
        margin: 0;
        padding: 10px;
    }
}

@media only screen and (max-width: 950px) {

}

@media only screen and (max-width: 500px) {

}
.company h1 {
    padding: 50px 25px;
    font-weight: 100;
    font-size: 48px;
}

.company-button {
    margin-top: 25px;
    margin-left: 25px;
    width: 97%;
    padding: 15px;
    background-color: #67b44b;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
}

@media only screen and (max-width: 1170px) {
    .company-button {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 950px) {

}

@media only screen and (max-width: 500px) {

}
.admin-location-create {
    padding-left: 25px;
    padding-top: 25px;
}

.admin-location-create-inputs {
    display: flex;
    justify-content: space-between;
}

.admin-location-create-inputs input:first-child {
    width: 50%;
    margin-right: 10px;
}

.admin-location-create-inputs input:last-child {
    width: 50%;
    margin-left: 10px;
}

.admin-location-create-inputs input {
    padding: 15px;
    border: 1px solid #dadada;
    border-radius: 10px;
    outline: none;
}

.admin-location-create button {
    margin-top: 35px;
    width: 100%;
    padding: 15px;
    background-color: #67b44b;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
}

@media only screen and (max-width: 1170px) {
    
    .admin-location-create {
        padding: 10px;
        padding-top: 25px;
    }

}

@media only screen and (max-width: 950px) {

}

@media only screen and (max-width: 500px) {
    .admin-location-create-inputs {
        display: block;
    }
    .admin-location-create-inputs input:first-child {
        width: 100%;
        margin: 0;
        margin-top: 10px;
    }
    
    .admin-location-create-inputs input:last-child {
        width: 100%;
        margin: 0;
        margin-top: 10px;
    }
}

.admin-location-item {
    display: flex;
    justify-content: space-between;
}

.admin-location-item-location{
    width: 50%;
    border: 1px solid #dadada;
    margin: 10px 0;
    padding: 15px 10px;
    border-radius: 10px;
}

.admin-location-item-street-street  {
    border: 1px solid #dadada;
    border-radius: 10px;
    padding: 15px 10px;
    width: 90%;
}

.admin-location-item-street svg {
    margin: 19px 0 0 0;
    cursor: pointer;
}

.admin-location-item-street {
    width: 50%;
    margin: 10px 0;
    display: flex;
}

.admin-location-item span {
    font-weight: bolder;
}

.admin-location-item div:first-child {
    margin-right: 10px;
}
.admin-location-item div:last-child {
    margin-left: 10px;
}

.admin-location-delete {
    text-align: center;
}

.admin-location-delete h2 {
    padding: 10px 0 20px;
}

.admin-location-delete span {
    font-weight: bolder;
}

.admin-location-delete-buttons button {
    padding: 20px 75px;
    border-radius: 10px;
    color: #fff;
    border: none;
    background-color: #b44b4b;
    margin: 25px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
}

.admin-location-delete-buttons button:last-child {
    background-color: #67b44b;
}

.admin-location-delete-location {
    font-weight: bolder;
    margin-top: 10px;
}


@media only screen and (max-width: 1170px) {
    
}

@media only screen and (max-width: 950px) {

}

@media only screen and (max-width: 500px) {
    .admin-location-item {
        display: block;
        margin-bottom: 40px;
    }
    .admin-location-item-location {
        width: 100%;
    }
    .admin-location-item-street {
        width: 100%;
    }
    .admin-location-item div:last-child {
        margin-left: 0;
    }
}
.admin-locations-edit {
    margin-left: 25px;
    margin-top: 50px;
}

.admin-locations-edit h2 {
    margin-bottom: 30px;
}


@media only screen and (max-width: 1170px) {
    .admin-locations-edit {
        margin-left: 0;
        padding: 10px;
    }
}

@media only screen and (max-width: 950px) {

}

@media only screen and (max-width: 500px) {

}
.admin-location h1 {
    padding: 50px 25px;
    font-size: 48px;
    font-weight: 100;
}

.admin-locations-button {
    margin-top: 35px;
    width: 97%;
    padding: 15px;
    background-color: #67b44b;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
    margin-left: 25px;
}


@media only screen and (max-width: 1170px) {
    
    .admin-locations-button {
        margin-left: 0px;
        margin: 10px;
    }
}

@media only screen and (max-width: 950px) {

}

@media only screen and (max-width: 500px) {

}

