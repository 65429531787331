.admin-location-item {
    display: flex;
    justify-content: space-between;
}

.admin-location-item-location{
    width: 50%;
    border: 1px solid #dadada;
    margin: 10px 0;
    padding: 15px 10px;
    border-radius: 10px;
}

.admin-location-item-street-street  {
    border: 1px solid #dadada;
    border-radius: 10px;
    padding: 15px 10px;
    width: 90%;
}

.admin-location-item-street svg {
    margin: 19px 0 0 0;
    cursor: pointer;
}

.admin-location-item-street {
    width: 50%;
    margin: 10px 0;
    display: flex;
}

.admin-location-item span {
    font-weight: bolder;
}

.admin-location-item div:first-child {
    margin-right: 10px;
}
.admin-location-item div:last-child {
    margin-left: 10px;
}

.admin-location-delete {
    text-align: center;
}

.admin-location-delete h2 {
    padding: 10px 0 20px;
}

.admin-location-delete span {
    font-weight: bolder;
}

.admin-location-delete-buttons button {
    padding: 20px 75px;
    border-radius: 10px;
    color: #fff;
    border: none;
    background-color: #b44b4b;
    margin: 25px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
}

.admin-location-delete-buttons button:last-child {
    background-color: #67b44b;
}

.admin-location-delete-location {
    font-weight: bolder;
    margin-top: 10px;
}


@media only screen and (max-width: 1170px) {
    
}

@media only screen and (max-width: 950px) {

}

@media only screen and (max-width: 500px) {
    .admin-location-item {
        display: block;
        margin-bottom: 40px;
    }
    .admin-location-item-location {
        width: 100%;
    }
    .admin-location-item-street {
        width: 100%;
    }
    .admin-location-item div:last-child {
        margin-left: 0;
    }
}