.admin-company-create {
    padding-left: 25px;
    padding-top: 25px;
}

.admin-company-create-inputs {
    display: flex;
    justify-content: space-between;
}

.admin-company-create-inputs input:first-child {
    width: 50%;
    margin-right: 10px;
}

.admin-company-create-inputs input:last-child {
    width: 50%;
    margin-left: 10px;
}

.admin-company-create-inputs input {
    padding: 15px;
    border: 1px solid #dadada;
    border-radius: 10px;
    outline: none;
}

.admin-company-create button {
    margin-top: 35px;
    width: 100%;
    padding: 15px;
    background-color: #67b44b;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
}


@media only screen and (max-width: 1170px) {
    .admin-company-create {
        padding-left: 0;
        padding: 10px;
    }
}

@media only screen and (max-width: 950px) {

}

@media only screen and (max-width: 500px) {

    .admin-company-create-inputs {
        display: block;
    }
    .admin-company-create-inputs input:first-child {
        width: 100%;
        margin: 0;
        margin-top: 10px;
    }
    
    .admin-company-create-inputs input:last-child {
        width: 100%;
        margin: 0;
        margin-top: 10px;
    }


}