.user-modal-edit {
    padding-top: 50px;
}

.user-modal-edit div:first-child {
    display: flex;
    justify-content: space-between;
}

.user-modal-edit div:first-child input {
    padding: 15px;
    border: 1px solid #dadada;
    border-radius: 10px;
    outline: none;
}

.user-modal-edit div:first-child input:first-child {
    width: 100%;

}

.user-modal-edit-info {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
    margin: 50px 0;
}

.user-modal-edit input {
    border-radius: 10px;
    padding: 15px;
    border: 1px solid #dadada;
    outline: none;
}

.user-modal-edit button {
    width: 100%;
    padding: 15px;
    background-color: #67b44b;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
}

.user-modal-edit-dropdown {
    border-radius: 10px;
    padding: 15px;
    border: 1px solid #dadada;
    display: flex;
    justify-content: space-between;
}

.user-modal-edit-dropdown span {
    font-weight: bolder;
}

.user-modal-edit-dropdown svg {
    margin-top: 4px;
}

.user-modal-edit-h2 {
    text-align: center;
}

.user-modal-edit-buttons {
    display: flex;
    justify-content: space-between;
}

.user-modal-edit-buttons button {
    padding: 20px 75px;
    border-radius: 10px;
    color: #fff;
    border: none;
    background-color: #b44b4b;
    margin-bottom: 15px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
    width: 48%;
}

.user-modal-edit-buttons button:last-child {
    background-color: #67b44b;
}

@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 900px) {
    .user-modal-edit-info {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 5%;
        margin: 50px 0;
    }

    .user-modal-edit div:first-child {
        display: block;
        justify-content: space-between;
    }

    .user-modal-edit-info div:first-child {
        display: flex;
        justify-content: space-between;
        margin: 0;
    }

    .user-modal-edit div:first-child input:first-child {
        width: 100%;
        margin-right: 0;
    }
    
    .user-modal-edit div:first-child input:last-child {
        width: 100%;
        margin-left: 0;
    }

    .user-modal-edit-info {
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .user-modal-edit div:first-child input {
        margin: 10px 0;
    }
    .user-modal-edit-buttons {
        display: block;
        justify-content: space-between;
    }
    .user-modal-edit-buttons button {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {

}