.edit h1 {
    padding: 50px 25px;
    font-weight: 100;
    font-size: 48px;
}

.edit-inputs {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
    padding-left: 25px;
}

.edit-input-div label, .edit-input-div input {
    display: inline-block;
}
.edit-input-div input {
    border: none;
    outline: none;
    padding-left: 1px;
    font-size: 15px;
    font-weight: bolder;
}

.edit-input-div {
    border: #dadada 1px solid;
    padding: 10px;
    margin: 25px 0;
    border-radius: 10px;
}

.edit-input {
    border-radius: 10px;
    border: #dadada 1px solid;
    padding: 10px;
    font-size: 15px;
    outline: none;
}

.edit-password {
    text-align: center;
    padding: 30px;
}

.edit-input-buttons button {
    margin-left: 25px;
    width: 97.5%;
    padding: 15px 20px;
    background-color: #67b44b;
    border: none;
    color: #fff;
    font-size: 15px;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
}

.edit-input-buttons button:last-child {
    margin-top: 25px;
}

.edit-password-inputs {
    display: flex;
    justify-content: space-between;
}

.edit-password input {
    width: 47%;
}

@media only screen and (max-width: 1170px) {
    .edit {
        padding: 15px;
    }
}

@media only screen and (max-width: 800px) {
    .edit-inputs {
        grid-template-columns: 100%;
        grid-gap: 0%;
        padding-left: 25px;
    }

    .edit-input-buttons button {
        width: 93%;
    }

    .edit-password input {
        width: 100%;
        margin: 5px 0;
    }

    .edit-password-inputs {
        display: block;
    }

    .edit-input-div {
        margin: 10px 0;
    }
}

@media only screen and (max-width: 700px) {
    .manual-button {
        width:95%;
    }
}
