.modal {
    position: relative;
    margin: 0 auto;
    height: auto;
    margin-top: 15%;
    width: 900px;
    background-color: rgb(255, 255, 255);
    z-index: 10000;
    cursor: default;
    border-radius: 10px;
    padding: 25px;
    -webkit-box-shadow: 4px 6px 76px -8px rgba(0,0,0,0.44);
    -moz-box-shadow: 4px 6px 76px -8px rgba(0,0,0,0.44);
    box-shadow: 4px 6px 76px -8px rgba(0,0,0,0.44);
}

/* Media Query */

@media only screen and (max-width: 930px) {
    .modal {
        width: 98%;
    }
}
