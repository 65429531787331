.admin-edit {
    padding-left: 25px;
    padding-top: 50px;
}

.admin-edit h2 {
    padding-top: 50px;
    padding-bottom: 25px;
}

@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 900px) {
    .admin-edit {
        padding: 0;
        padding: 10px;
    }
}

@media only screen and (max-width: 500px) {

}