.admin-locations-edit {
    margin-left: 25px;
    margin-top: 50px;
}

.admin-locations-edit h2 {
    margin-bottom: 30px;
}


@media only screen and (max-width: 1170px) {
    .admin-locations-edit {
        margin-left: 0;
        padding: 10px;
    }
}

@media only screen and (max-width: 950px) {

}

@media only screen and (max-width: 500px) {

}