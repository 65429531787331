.company-edit {
    margin-left: 25px;
    margin-top: 50px;
}

.company-edit h2 {
    margin-bottom: 30px;
}

.company-edit-button {
    margin-top: 25px;
    width: 100%;
    padding: 15px;
    background-color: #67b44b;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
}


@media only screen and (max-width: 1170px) {
    .company-edit {
        margin: 0;
        padding: 10px;
    }
}

@media only screen and (max-width: 950px) {

}

@media only screen and (max-width: 500px) {

}